import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import "./roadmap.scss"

export default function Roadmap({
  data: {
    strapiRoadmap: { title, seo, roadmapItem },
  },
}) {
  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname={"roadmap"}
      />
      <section className="section is-medium" id="roadmap">
        <div className="container">
          <h1 className="title is-1">{title}</h1>
        </div>
        <div className="container my-6 roadmap-list-container">
          {roadmapItem.map(({ id, date, milestoneGroup }) => (
            <div key={id}>
              <div className="roadmap-item">
                <div className="is-flex">
                  <div className="is-flex is-flex-direction-column is-align-center mr-5">
                    <div className="decorator" />
                    <div className="gradient-line is-flex-grow-1" />
                  </div>
                  <div>
                    <h4 className="title is-4 mt-3 mb-6">{date}</h4>
                    {milestoneGroup.map(
                      ({
                        id: milestoneGroupId,
                        title: milestoneGroupTitle,
                        milestone,
                      }) => (
                        <div key={`mileStoneGroup-${milestoneGroupId}`}>
                          <h6 className="title is-6 my-3">
                            {milestoneGroupTitle}
                          </h6>
                          <ul className="mb-6">
                            {milestone.map(
                              ({ id: milestoneId, title: milestoneTitle }) => (
                                <li key={`mileStone-${milestoneId}`} className="mb-1">
                                  {milestoneTitle}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiRoadmap {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      title
      roadmapItem {
        id
        date
        milestoneGroup {
          id
          title
          milestone {
            id
            title
          }
        }
      }
    }
  }
`
