import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  title,
  lang = "en",
  meta = [],
  jsonLDSchema,
  image: metaImage,
  keywords,
  pathname,
}) {
  const { strapiSiteMetadata } = useStaticQuery(graphql`
    {
      strapiSiteMetadata {
        siteUrl
        logo {
          height
          width
          localFile {
            url
          }
        }
      }
    }
  `)

  const image =
    (metaImage && metaImage.src) || strapiSiteMetadata.logo.localFile.url

  const canonical =
    pathname === "/"
      ? strapiSiteMetadata.siteUrl
      : pathname
      ? `${strapiSiteMetadata.siteUrl}/${pathname}/`
      : null

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            name: "keywords",
            content: keywords,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            property: "og:image",
            content: image,
          },
          {
            property: "og:image:width",
            content: !metaImage
              ? strapiSiteMetadata.logo.width
              : metaImage.width,
          },
          {
            property: "og:image:height",
            content: !metaImage
              ? strapiSiteMetadata.logo.height
              : metaImage.height,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
        ].concat(meta)}
      />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      {jsonLDSchema && (
        <Helmet defer={false}>
          <script type="application/ld+json">
            {jsonLDSchema}
          </script>
        </Helmet>
      )}
    </>
  )
}

export default SEO
